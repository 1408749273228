import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
	url: string;
	data: Data;
	params: Params;
	queryParams: Params;
	oficinaParams: Params | null;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
	serialize(routerState: RouterStateSnapshot): RouterStateUrl {
		let route = routerState.root;

		/* Para obtener la oficina */
		const firstChild = routerState.root.firstChild;

		while (route.firstChild) {
			route = route.firstChild;
		}

		const {
			url,
			root: { queryParams },
		} = routerState;

		const { params, data } = route;

		const oficinaParams = firstChild?.params ?? null;

		// Vamos a retornar solo estos 4 campos en lugar de la instancia completa.
		// Si necesitas algún campo adicional lo puedes agregar
		return { url, data, params, queryParams, oficinaParams };
	}
}
