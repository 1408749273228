import { Action } from '@ngrx/store';
import { User } from '../models/user.model';

export enum AuthActionTypes {
	Login = '[Login] Action',
	Logout = '[Logout] Action',
	Register = '[Register] Action',
	UserRequested = '[Request User] Action',
	UserLoaded = '[Load User] Auth API',
	AccionRequest = '[Request Acciones] Action',
	FirstResetPassword = '[First Reset Password] Action',
}

export class Login implements Action {
	readonly type = AuthActionTypes.Login;
	constructor(public payload: { authToken: string; idToken: string; refreshToken: string; user: User }) {}
}

export class Logout implements Action {
	readonly type = AuthActionTypes.Logout;
}

export class Register implements Action {
	readonly type = AuthActionTypes.Register;
	constructor(public payload: { authToken: string }) {}
}

export class UserRequested implements Action {
	readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
	readonly type = AuthActionTypes.UserLoaded;
	constructor(public payload: { user: User }) {}
}

export class FirstResetPassword implements Action {
	readonly type = AuthActionTypes.FirstResetPassword;
	constructor(public payload: { user: User; sessionId: string }) {}
}
export class AccionRequest implements Action {
	readonly type = AuthActionTypes.AccionRequest;
}

export type AuthActions = Login | Logout | Register | UserRequested | UserLoaded | FirstResetPassword | AccionRequest;
