import { SciEmpresa } from '~models/config/interface/SciEmpresa';
import { TbEntidad } from '~models/maestros/interface/TbEntidad';
import { TbPersona } from '~models/maestros/interface/TbPersona';
import { BaseModel } from '../../_base/crud';

export class User extends BaseModel {
	id: number = 0;

	accessToken: string | null = null;
	refreshToken: string | null = null;

	fullname: string = '';
	username: string = '';
	tbEntidad: Partial<TbEntidad> = <TbEntidad>{};
	tbPersona: Partial<TbPersona> = <TbPersona>{};
	sciEmpresa: Partial<SciEmpresa> = <SciEmpresa>{};
	correoElectronico: string = '';
	estado: boolean = false;
}
