import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { AppState } from '@core/store/';
import { isLoggedIn } from '../../store/selectors/auth.selectors';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private readonly _store: Store<AppState>) {}

	canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this._guard();
	}

	canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this._guard();
	}

	private _guard(): Observable<boolean> {
		return this._store.pipe(
			select(isLoggedIn),
			tap((loggedIn) => {
				if (!loggedIn) {
					window.location.href = environment.URL_LOGIN;
				}
			})
		);
	}
}
