import { Action, createReducer, on } from '@ngrx/store';
import { SciFormularioAccionListado } from '../models/SciFormularioAccionListado';
import { cargarAccesos, cargarAccesosError, cargarAccesosSuccess } from '../actions/accesos.actions';

export interface AccesosState {
	accesos: SciFormularioAccionListado[];
	loaded: boolean;
	loading: boolean;
	error: any;
}

export const accesosInitialState: AccesosState = {
	accesos: [],
	loaded: false,
	loading: false,
	error: null,
};

const _accesosReducer = createReducer(
	accesosInitialState,

	on(cargarAccesos, (state) => ({ ...state, loading: true })),

	on(cargarAccesosSuccess, (state, { sciFormularioAccionListado }) => ({
		...state,
		loading: false,
		loaded: true,
		accesos: [...sciFormularioAccionListado],
	})),

	on(cargarAccesosError, (state, { payload }) => ({
		...state,
		loading: false,
		loaded: false,
		error: {
			url: payload.url,
			name: payload.name,
			message: payload.message,
		},
	}))
);

export function accesosReducer(state: AccesosState | undefined, action: Action): AccesosState {
	return _accesosReducer(state, action);
}
