<!-- <div
	class="kt-subheader kt-grid__item sumax-subheader"
	[ngClass]="{ 'kt-container--clear': clear }"
	id="kt_subheader">
	<div class="kt-container sumax-subheader-container" [ngClass]="{ 'kt-container--fluid': fluid }">
		<div class="kt-subheader__main">
			<menu-horizontal></menu-horizontal>
		</div>
		<div class="kt-subheader__toolbar">
			<div class="kt-subheader__wrapper"></div>
		</div>
	</div>
</div> -->

<div class="kt-container sumax-subheader-container" [ngClass]="{ 'kt-container--fluid': fluid }">
	<div class="kt-subheader__main">
		<menu-horizontal></menu-horizontal>
	</div>
	<div class="kt-subheader__toolbar">
		<div class="kt-subheader__wrapper"></div>
	</div>
</div>
