<!-- begin: Header -->
<!-- <div
	ktHeader
	#ktHeader
	class="kt-header kt-grid__item sumax_header kt-header--fixed"
	[ngClass]="getBackground()"
	id="kt_header">
	<ng-container *ngIf="loader.progress$ | async as _value">
		<ngb-progressbar
			class="kt-loading-bar"
			*ngIf="_value || 0 > 0"
			[value]="_value"
			height="3px"></ngb-progressbar>
	</ng-container> -->
<!-- begin: Header Menu -->
<!-- <kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal> -->
<!-- <kt-header-title *ngIf="menuHeaderDisplay" [title]="title" [desc]="desc"></kt-header-title> -->
<!-- end: Header Menu -->

<!-- begin:: Header Topbar -->
<!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
<!-- <div *ngIf="!menuHeaderDisplay"></div>
	<kt-topbar></kt-topbar> -->
<!-- end:: Header Topbar -->
<!-- </div> -->
<!-- end: Header -->

<!-- begin: Header -->
<div ktHeader #ktHeader class="kt-container kt-header kt-grid__item sumax_header" [ngClass]="getBackground()" id="kt_header">
	<ng-container *ngIf="loader.progress$ | async as _value">
		<ngb-progressbar class="kt-loading-bar" *ngIf="_value || 0 > 0" [value]="_value" height="3px"></ngb-progressbar>
	</ng-container>
	<!-- begin: Header Menu -->
	<!-- <kt-menu-horizontal *ngIf="menuHeaderDisplay"></kt-menu-horizontal> -->
	<kt-header-title *ngIf="menuHeaderDisplay" [title]="title" [desc]="desc"></kt-header-title>
	<!-- end: Header Menu -->

	<!-- begin:: Header Topbar -->
	<!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
	<div *ngIf="!menuHeaderDisplay"></div>
	<kt-topbar></kt-topbar>
	<!-- end:: Header Topbar -->
</div>
<!-- end: Header -->
