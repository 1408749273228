import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { interval } from 'rxjs';

@Injectable()
export class SwUpdateNotifierService {
	constructor(private readonly _swUpdate: SwUpdate, private readonly _matSnackBar: MatSnackBar) {}

	init(): void {
		this._swUpdate.versionUpdates
			.pipe(
				filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_DETECTED'),
				map((evt) => ({
					type: 'UPDATE_AVAILABLE',
					current: evt.currentVersion,
					available: evt.latestVersion,
				}))
			)
			.subscribe(() => {
				const ref = this._matSnackBar.open('Actualización disponible', 'Reload');

				ref.onAction().subscribe(() => {
					void this._swUpdate.activateUpdate().then(() => document.location.reload());
				});
			});

		if (environment.production) {
			interval(10000).subscribe(() => {
				void this._swUpdate.checkForUpdate();
			});
		}
	}
}
