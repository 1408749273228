<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
	<button mat-button [disableRipple]="true" id="kt_header_mobile_toggler" class="small_screen_menu_button">
		<mat-icon svgIcon="menu_bar"></mat-icon>
	</button>

	<!-- <kt-quick-action [bgImage]="'assets/media/misc/bg-2.jpg'" [skin]="'dark'" [icon]="'assets/media/icons/svg/Media/Equalizer.svg'" [useSVG]="true"></kt-quick-action> -->

	<select-office></select-office>

	<kt-user-profile [badge]="true"></kt-user-profile>
</div>
