import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { LayoutConfigService } from '@core/_base/layout/services/layout-config.service';
import { LayoutRefService } from '@core/_base/layout/services/layout-ref.service';
import { Subscription } from 'rxjs';
// Angular
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
// Object-Path
import objectPath from 'object-path';
// Loading bar

// Layout

// HTML Class Service
import { HtmlClassService } from '../html-class.service';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
	selector: 'kt-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterViewInit {
	// Public properties
	menuHeaderDisplay!: boolean;
	fluid!: boolean;

	// Private properties
	private subscriptions: Subscription[] = [];

	title = '';
	desc = '';

	@ViewChild('ktHeader', { static: true }) ktHeader!: ElementRef;
	constructor(
		private router: Router,
		private layoutRefService: LayoutRefService,
		private layoutConfigService: LayoutConfigService,
		public loader: LoadingBarService,
		public htmlClassService: HtmlClassService,
		public subheaderService: SubheaderService
	) {
		// page progress bar percentage
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				// set page progress bar loading to start on NavigationStart event router
				this.loader.start();
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loader.increment(35);
			}
			if (event instanceof RouteConfigLoadEnd) {
				this.loader.increment(75);
			}
			if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				// set page progress bar loading to end on NavigationEnd event router
				this.loader.complete();
			}
		});
	}
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();

		// get menu header display option
		this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');
		// header width fluid
		this.fluid = objectPath.get(config, 'header.self.width') === 'fluid';

		// animate the header minimize the height on scroll down. to be removed, not applicable for default demo
		/* if (objectPath.get(config, 'header.self.fixed.desktop.enabled') || objectPath.get(config, 'header.self.fixed.desktop')) {
			// header minimize on scroll down
			this.ktHeader.nativeElement.setAttribute('data-ktheader-minimize', '1');
		  } */
	}

	ngAfterViewInit(): void {
		// keep header element in the service
		this.layoutRefService.addElement('header', this.ktHeader.nativeElement);

		this.subscriptions.push(
			this.subheaderService.title$.subscribe((bt) => {
				// breadcrumbs title sometimes can be undefined
				// console.log('subheaderService', bt);
				if (bt) {
					Promise.resolve(null).then(() => {
						this.title = bt.title;
						this.desc = bt?.desc || '';
					});
				}
			})
		);
	}

	getBackground() {
		let classes = {};
		switch (this.title) {
			case 'Ventas':
				classes = {
					finanzas: true,
				};
				break;
			case 'Aduanas':
				classes = {
					aduanas: true,
				};
				break;
			case 'Carga':
				classes = {
					carga: true,
				};
				break;
			case 'Transporte':
				classes = {
					transporte: true,
				};
				break;
			case 'Configuracion':
				classes = {
					configuracion: true,
				};
				break;
			case 'Maestros':
				classes = {
					maestros: true,
				};
				break;
		}

		return classes;
	}
}
