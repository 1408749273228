import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as menuActions from '../actions/menu.actions';
import * as accesosActions from '../actions/accesos.actions';
import { AuthService } from '@core/security/services/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { currentAccesos } from '../selectors/accesos.selectors';
import { Router } from '@angular/router';

@Injectable()
export class MenuEffects {
	constructor(private actions$: Actions, private store: Store<AppState>, private auth: AuthService, private router: Router) {}

	cargarMenu$ = createEffect(() =>
		this.actions$.pipe(
			ofType(menuActions.cargarMenu),
			withLatestFrom(this.store.pipe(select(currentAccesos))),
			mergeMap(([action, formularioAcciones]) =>
				this.auth.findAllByCodigoSistemaAndIdFormularioList(formularioAcciones.map((formA: any) => formA.idFormulario)).pipe(
					map((formularioAcciones) => {
						this.store.dispatch(accesosActions.validarAccesoFormulario());
						return menuActions.cargarMenuSuccess({ sciMenu: formularioAcciones });
					}),
					catchError((err) => {
						this.store.dispatch(menuActions.cargarMenuError({ payload: err }));
						return of(accesosActions.validarAccesoFormulario());
					})
				)
			)
		)
	);

	// cargarAccesosSucces$ = createEffect(
	//     () => this.actions$.pipe(
	//         ofType( accesosActions.cargarAccesosSuccess ),
	//         tap(
	//             (action) => this.store.dispatch(menuActions.cargarMenu()),
	//         )
	//     )
	// , {dispatch: false});
}
