import { NgModule } from '@angular/core';
import { RouterModule, Routes, Route } from '@angular/router';
import { AuthGuard } from '@core/security/guards/auth.guard';
import { BaseComponent } from '@theme/base/base.component';
import { ErrorPageComponent } from '@theme/error-page/error-page.component';
import { ConsultaModule } from 'app/modules/consulta/consulta.module';
import { ControlModule } from 'app/modules/control/control.module';
import { DashboardModule } from 'app/modules/dashboard/dashboard.module';
import { GestionModule } from 'app/modules/gestion/gestion.module';
import { HerramientaModule } from 'app/modules/herramienta/herramienta.module';

const defaultRute: Route = {
	component: BaseComponent,
	canActivate: [AuthGuard],
	canActivateChild: [AuthGuard],
	children: [
		{
			path: 'dashboard',
			loadChildren: (): Promise<typeof DashboardModule> => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			data: {
				title: 'Tablero Principal',
			},
		},
		{
			path: 'gestion',
			loadChildren: (): Promise<typeof GestionModule> => import('app/modules/gestion/gestion.module').then((m) => m.GestionModule),
		},
		{
			path: 'control',
			loadChildren: (): Promise<typeof ControlModule> => import('app/modules/control/control.module').then((m) => m.ControlModule),
		},
		{
			path: 'consulta',
			loadChildren: (): Promise<typeof ConsultaModule> => import('app/modules/consulta/consulta.module').then((m) => m.ConsultaModule),
		},
		{
			path: 'reporte',
			loadChildren: (): Promise<typeof ConsultaModule> => import('app/modules/reporte/reporte.module').then((m) => m.ReporteModule),
		},
		{
			path: 'herramienta',
			loadChildren: (): Promise<typeof HerramientaModule> => import('app/modules/herramienta/herramienta.module').then((m) => m.HerramientaModule),
		},
		{ path: '', redirectTo: '/carga/dashboard', pathMatch: 'full' },
		{ path: '**', redirectTo: '../error/403', pathMatch: 'full' },
	],
};

const routes: Routes = [
	{
		path: 'carga',
		...defaultRute,
	},
	{
		path: 'oficina/:idOficina',
		...defaultRute,
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'Tranquilo ratón, aún no desarrollamos esto.<br> Please, contact.',
		},
	},
	{ path: '', redirectTo: 'carga', pathMatch: 'full' },
	{ path: '**', redirectTo: '/error/403', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
