import { Action, createReducer, on } from '@ngrx/store';
import { cargarMenu, cargarMenuError, cargarMenuSuccess } from '../actions/menu.actions';
import { SciMenuListado } from '../models/SciMenuListado';

export interface MenuState {
	menu: SciMenuListado[];
	loaded: boolean;
	loading: boolean;
	error: any;
}

export const menuInitialState: MenuState = {
	menu: [
		{
			title: 'Tablero Principal',
			page: 'dashboard',
			order: 1,
			icon: 'flaticon2-chart',
			bullet: 'dot',
			idMenu: 35,
		},
		{
			title: 'Favoritos',
			page: 'dashboard',
			order: 2,
			icon: 'flaticon-star',
			bullet: 'dot',
			idMenu: 36,
		},
	],
	loaded: false,
	loading: false,
	error: null,
};

const _menuReducer = createReducer(
	menuInitialState,

	on(cargarMenu, (state) => ({ ...state, loading: true })),

	on(cargarMenuSuccess, (state, { sciMenu }) => ({
		...state,
		loading: false,
		loaded: true,
		menu: [...sciMenu],
	})),

	on(cargarMenuError, (_, { payload }) => ({
		...menuInitialState,
		error: {
			url: payload.url,
			name: payload.name,
			message: payload.message,
		},
	}))
);

export function menuReducer(state: MenuState | undefined, action: Action) {
	return _menuReducer(state, action);
}
