// Angular
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
	selector: 'kt-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
	constructor(sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
		iconRegistry.addSvgIcon('menu_bar', sanitizer.bypassSecurityTrustResourceUrl('assets/media/icons/menu.svg'));
	}
}
