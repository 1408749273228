export class MenuConfig {
	// VENTAS
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Tablero Principal',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-chart',
					page: '/ventas/dashboard', // colocar ruta del dashboard
				},
				{
					title: 'Favoritos',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-star',
					/* page: '/transporte/gestion' colocar ruta del dashboard */
				},
				{
					title: 'Gestión',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-doc',
					submenu: [
						{
							title: 'Facturas',
							page: '/ventas/gestion/comprobantes/01',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Boletas',
							page: '/ventas/gestion/comprobantes/03',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Notas de Crédito',
							page: '/ventas/gestion/comprobantes/07',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Notas de Débito',
							page: '/ventas/gestion/comprobantes/08',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Transmisión Electrónica',
							page: '/ventas/gestion/transmision/comprobante',
							bullet: 'dot',
							icon: 'fas fa-paper-plane',
						},
					],
				},
				{
					title: 'Control',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-squares',
					submenu: [
						{
							title: 'Reporte de Ventas',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Reporte Sunat',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
				{
					title: 'Reportes',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-poll-symbol',
					submenu: [
						{
							title: 'Reporte de Ventas',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Reporte Sunat',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
				{
					title: 'Herramientas',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-settings',
					submenu: [
						{
							title: 'Cierre de Período',
							page: '/ventas/herramientas/cierre-periodo',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Copiar Documentos',
							page: '/ventas/herramientas/copia',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
			],
		},
	};

	public configuracion: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Tablero Principal',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-chart',
					// icon: 'flaticon2-line-chart'
					//  icon: 'flaticon2-analytics',
					page: '/configuracion/dashboard', // colocar ruta del dashboard
				},
				{
					title: 'Favoritos',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-star',
					/* page: '/transporte/gestion'   colocar ruta del dashboard*/
				},
				{
					title: 'Gestión',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-doc',
					submenu: [
						{
							title: 'Usuarios',
							page: '/configuracion/usuario',
							bullet: 'dot',
							icon: 'flaticon-users-1',
						},
						{
							title: 'Perfiles',
							page: '/configuracion/perfil',
							bullet: 'dot',
							icon: 'flaticon2-user',
						},
						{
							title: 'Empleados',
							page: '/configuracion/empleado',
							bullet: 'dot',
							icon: 'flaticon-avatar',
						},
						{
							title: 'Áreas',
							page: '/configuracion/area',
							bullet: 'dot',
							icon: ' flaticon2-group ',
						},
						{
							title: 'Cargos',
							page: '/configuracion/cargo',
							bullet: 'dot',
							icon: 'flaticon2-user-1',
						},
					],
				},
				{
					title: 'Control',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-squares',
					submenu: [
						{
							title: 'Accesos a Perfiles',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Configuración de Códigos',
							page: '/configuracion/configuracion-codigos',
							bullet: 'dot',
							icon: 'flaticon-clipboard',
						},
					],
				},
				{
					title: 'Reportes',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-poll-symbol',
					submenu: [
						{
							title: 'Reporte General',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
					],
				},
				{
					title: 'Herramientas',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-settings',
				},
			],
		},
	};

	public maestros: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Tablero Principal',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-chart',
					page: '/maestros/dashboard', // colocar ruta del dashboard
				},
				{
					title: 'Favoritos',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-star',
					/* page: '/maestros/gestion'   colocar ruta del dashboard*/
				},
				{
					title: 'Gestión',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-doc',
					submenu: [
						{
							title: 'Conceptos',
							page: '/maestros/concepto',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Divisiones',
							page: '/maestros/division',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Series',
							page: '/maestros/proveedor',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Proveedores',
							page: '/maestros/proveedor',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Clientes',
							page: '/maestros/cliente',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Proyectos',
							page: '/maestros/proveedor',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
				{
					title: 'Control',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-squares',
					submenu: [],
				},
				{
					title: 'Reportes',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-poll-symbol',
					submenu: [],
				},
				{
					title: 'Herramientas',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-settings',
					submenu: [],
				},
			],
		},
	};

	public carga: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Tablero Principal',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-chart',
					page: '/carga/dashboard', // colocar ruta del dashboard
				},
				{
					title: 'Favoritos',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-star',
					/* page: '/carga/gestion'   colocar ruta del dashboard*/
				},
				{
					title: 'Gestión',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-doc',
					submenu: [
						{
							title: 'Routing Order',
							page: '/carga/gestion/routing-order',
							bullet: 'dot',
							icon: 'flaticon2-website',
						},
						{
							title: 'Solicitud de Transbordo',
							page: '/carga/gestion/solicitud-transbordo',
							bullet: 'dot',
							icon: 'flaticon2-website',
						},
						{
							title: 'Seguimiento Operativo',
							page: '/carga/gestion/seguimiento-operativo',
							bullet: 'dot',
							icon: 'flaticon2-website',
						},
						{
							title: 'Provisión Administrativa',
							page: '/carga/gestion/provision-administrativa',
							bullet: 'dot',
							icon: 'flaticon2-website',
						},
					],
				},
				{
					title: 'Control',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-squares',
					submenu: [
						{
							title: 'Contrato de Transporte',
							page: '/carga/control/contrato-transporte',
							bullet: 'dot',
							icon: 'flaticon2-website',
						},
						{
							title: 'Nave (Buque)',
							page: '/carga/control/nave-buque',
							bullet: 'dot',
							icon: 'fa fa-ship',
						},
						{
							title: 'Documentos',
							page: '/carga/control/documentos',
							bullet: 'dot',
							icon: 'fas fa-file-alt',
						},
						// ELIMINAR
						{
							title: 'test',
							bullet: 'dot',
							icon: 'flaticon2-website',
							submenu: [
								{
									title: 'AgGrid',
									page: '/carga/test/aggrid',
									bullet: 'dot',
									icon: 'flaticon2-website',
								},
								{
									title: 'Pagination',
									page: '/carga/test/pagination',
									bullet: 'dot',
									icon: 'flaticon2-website',
								},
							],
						},
					],
				},
				{
					title: 'Informes',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-poll-symbol',
					submenu: [
						{
							title: 'Reportes',
							bullet: 'dot',
							icon: 'flaticon2-website',
							submenu: [],
						},
						{
							title: 'Consultas',
							bullet: 'dot',
							icon: 'flaticon2-website',
							submenu: [
								{
									title: 'Programación de naves',
									page: '/carga/informe/consulta/programacion-naves',
									bullet: 'dot',
									icon: 'flaticon2-website',
								},
								{
									title: 'Control de contenedores',
									page: '/carga/informe/consulta/control-contenedores',
									bullet: 'dot',
									icon: 'flaticon2-website',
								},
							],
						},
					],
				},
				{
					title: 'Herramientas',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-settings',
					submenu: [
						{
							title: 'Configuración',
							bullet: 'dot',
							icon: 'fas fa-tools',
							submenu: [
								{
									title: 'Seguimiento operativo',
									page: '/carga/herramienta/configuracion/seguimiento-operativo',
									bullet: 'dot',
									icon: 'flaticon2-website',
								},
							],
						},
						{
							title: 'Emisión de documentos',
							page: '/carga/herramienta/emision-docs/documentos-emitir',
							bullet: 'dot',
							icon: 'flaticon2-website',
						},
						{
							title: 'Plantillas',
							page: '/carga/herramienta/plantillas/plantilla-listado',
							bullet: 'dot',
							icon: 'flaticon2-website',
						},
						{
							title: 'Envío de mensaje',
							page: '/carga/herramienta/mensaje',
							bullet: 'dot',
							icon: 'fa fa-envelope',
						},
					],
				},
			],
		},
	};

	public get configs(): any {
		return this.defaults;
	}

	public get cargaBar(): any {
		return this.carga;
	}
}
