<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
	<div class="kt-base-header">
		<kt-header *ngIf="desktopHeaderDisplay"></kt-header>
		<subheader *ngIf="subheaderDisplay"></subheader>
	</div>

	<div ktContentAnimate class="kt-container kt-base-body">
		<router-outlet></router-outlet>
	</div>

	<div class="kt-base-footer">
		<kt-footer></kt-footer>
	</div>
</ng-container>

<ng-template #blankLayout>
	<div ktContentAnimate class="kt-container kt-base-body">
		<router-outlet></router-outlet>
	</div>
</ng-template>
