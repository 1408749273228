import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// language list
import { locale as enLang } from '@core/_config/i18n/en';
import { locale as chLang } from '@core/_config/i18n/ch';
import { locale as esLang } from '@core/_config/i18n/es';
import { locale as jpLang } from '@core/_config/i18n/jp';
import { locale as deLang } from '@core/_config/i18n/de';
import { locale as frLang } from '@core/_config/i18n/fr';
import { TranslationService, LayoutConfigService, SplashScreenService } from '@core/_base/layout';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { SessionStorageService } from '~shared/services/storage/session-storage.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SwUpdateNotifierService } from '@utils/services/sw-update-notifier.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
	isLoader!: boolean;

	private readonly _subscription$ = new Subscription();

	isDesktop$ = this._breakpointObserver.observe(`(min-width: 1024px)`).pipe(map((state) => state.matches));

	constructor(
		private readonly _translationService: TranslationService,
		private readonly _router: Router,
		private readonly _layoutConfigService: LayoutConfigService,
		private readonly _splashScreenService: SplashScreenService,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _titleService: Title,
		private readonly _sessionStorge: SessionStorageService,
		private readonly _breakpointObserver: BreakpointObserver,
		private readonly _swUpdateNotifierService: SwUpdateNotifierService
	) {
		this._translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
	}
	ngOnInit(): void {
		this._swUpdateNotifierService.init();
		this.isLoader = (this._layoutConfigService.getConfig('loader.enabled') as boolean) ?? false;

		this._subscription$.add(
			this._router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					setTimeout(() => {
						this._splashScreenService.hide();
						document.body.classList.add('kt-page--loaded');
						window.scrollTo(0, 0);
					}, 2000);
				}
			})
		);

		// Title tab
		this._subscription$.add(
			this._router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map(() => {
						let child = this._activatedRoute.firstChild;
						while (child) {
							if (child.firstChild) {
								child = child.firstChild;
							} else if (child.snapshot.data && child.snapshot.data['title']) {
								return child.snapshot.data['title'] as string;
							} else {
								return null;
							}
						}
						return null;
					})
				)
				.subscribe((title) => {
					if (title) {
						this._titleService.setTitle(title + ' | Carga');
					}
				})
		);
	}

	ngOnDestroy(): void {
		this._subscription$.unsubscribe();
	}
}
