import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventManager } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SciMenuListado, Submenu } from '@core/store/models/SciMenuListado';
import { StoreService } from '@utils/services/store.service';
import { map } from 'rxjs/operators';

type Menu = {
	title: string;
	page: string;
};

@Component({
	selector: 'sc-search-pages',
	templateUrl: './sc-search-pages.component.html',
})
export class ScSearchPagesModalComponent {
	menus$ = this._storeService.currentMenu$.pipe(map((menu) => menu.map((m) => this.flatArray(m)).flat()));

	formControl = new UntypedFormControl('');

	constructor(private readonly _storeService: StoreService, private _router: Router, private readonly _matDialogRef: MatDialogRef<ScSearchPagesModalComponent>) {}

	flatArray(menu: SciMenuListado | Submenu): Menu[] {
		if (menu.submenu) {
			return menu.submenu.map((d) => this.flatArray(d)).flat();
		}

		return [
			{
				title: menu.title,
				page: menu.page ?? '',
			},
		];
	}

	selectedChange(menu: Menu): void {
		void this._router.navigateByUrl('carga/' + menu.page);
		this._matDialogRef.close();
	}
}

@Component({
	selector: 'sc-search-pages',
	template: '',
	styles: [
		`
			:host {
				display: block;
				visibility: hidden;
				position: absolute;
				left: -100%;
				right: -100%;
			}
		`,
	],
})
export class ScSearchPagesComponent implements OnInit {
	constructor(private readonly _matDialog: MatDialog, private readonly _eventManager: EventManager) {}

	ngOnInit(): void {
		this._createEvent();
	}

	private _createEvent(): void {
		const refEvent = this._eventManager.addEventListener(document.body, 'keydown.Shift.K', () => {
			const dialogRef = this._matDialog.open(ScSearchPagesModalComponent, {
				width: '500px',
				height: '500px',
			});
			refEvent();

			dialogRef.afterClosed().subscribe(() => {
				this._createEvent();
			});
		});
	}
}
