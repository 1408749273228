export function isUndefinedOrNull<T>(obj: T): boolean {
	if (typeof obj == 'undefined' || String(obj) == 'Invalid date' || obj == null) {
		return true;
	}

	if (typeof obj == 'string' && obj == '') {
		return true;
	}

	return false;
}
