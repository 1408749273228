import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScSearchPagesComponent, ScSearchPagesModalComponent } from './sc-search-pages.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { PortletModule } from '@partials/content/general/portlet/portlet.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';

@NgModule({
	declarations: [ScSearchPagesComponent, ScSearchPagesModalComponent],
	exports: [ScSearchPagesComponent],
	imports: [CommonModule, MatDialogModule, MatListModule, PortletModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, UtilsModule],
})
export class ScSearchPagesModule {}
