import { LayoutConfig } from '@core/_config/layout.config';
import { PageConfigService } from '@core/_base/layout/services/page-config.service';
import { MenuConfigService } from '@core/_base/layout/services/menu-config.service';
import { LayoutConfigService } from '@core/_base/layout/services/layout-config.service';
import { MenuConfig } from '@core/_config/menu.config';
// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
// RxJS
import { Observable, Subscription } from 'rxjs';
// Object-Path
import objectPath from 'object-path';
// Layout

import { HtmlClassService } from '../html-class.service';

import { ActivatedRoute } from '@angular/router';
import { PageConfig } from '@core/_config/page.config';

@Component({
	selector: 'kt-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss'],
	host: {
		class: 'kt-base',
	},
	encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit, OnDestroy {
	// Public variables
	bartype: any;
	selfLayout!: string;
	asideDisplay!: boolean;
	asideSecondary!: boolean;
	subheaderDisplay!: boolean;
	desktopHeaderDisplay!: boolean;
	fitTop!: boolean;
	fluid!: boolean;

	baseConfig!: MenuConfig;

	// Private properties
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	constructor(
		private route: ActivatedRoute,
		private layoutConfigService: LayoutConfigService,
		private menuConfigService: MenuConfigService,
		private pageConfigService: PageConfigService,
		private htmlClassService: HtmlClassService
	) {
		// this.loadRolesWithPermissions();
		// this.servicioSubMenu();

		// register configs by demos
		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);

		this.pageConfigService.loadConfigs(new PageConfig().configs);

		// setup element classes
		this.htmlClassService.setConfig(this.layoutConfigService.getConfig());

		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe((layoutConfig) => {
			// reset body class based on global and page level layout config, refer to html-class.service.ts
			document.body.className = '';
			this.htmlClassService.setConfig(layoutConfig);
		});
		this.unsubscribe.push(subscr);
	}
	ngOnInit(): void {
		// Servicio detecta la ruta actual
		this.bartype = this.pageConfigService.getCurrentPageConfig();
		// FIXME MenuConfig  - dafaults (ventas)
		this.menuConfigService.loadConfigs(new MenuConfig().carga); // carga configuración de transporte
		// switch (this.bartype.page.title) {
		//   case 'Ventas':
		//     this.menuConfigService.loadConfigs(new MenuConfig().configs); // carga configuración de ventas
		//     break;

		//   case 'Transporte':
		//     this.menuConfigService.loadConfigs(new MenuConfig().transporte); // carga configuración de transporte
		//     break;
		// }
		const config = this.layoutConfigService.getConfig();
		this.selfLayout = objectPath.get(config, 'self.layout');
		this.asideDisplay = objectPath.get(config, 'aside.self.display');
		this.subheaderDisplay = objectPath.get(config, 'subheader.display');
		this.desktopHeaderDisplay = objectPath.get(config, 'header.self.fixed.desktop');
		this.fitTop = objectPath.get(config, 'content.fit-top');
		this.fluid = objectPath.get(config, 'content.width') === 'fluid';

		// let the layout type change
		const subscr = this.layoutConfigService.onConfigUpdated$.subscribe((cfg) => {
			setTimeout(() => {
				this.selfLayout = objectPath.get(cfg, 'self.layout');
			});
		});
		this.unsubscribe.push(subscr);
		// this.servicioSubMenu();
	}

	ngOnDestroy(): void {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
	}
}
