import { Action } from '@ngrx/store';
import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { User } from '../models/user.model';

export interface AuthState {
	loggedIn: boolean;
	authToken: string;
	user: User | null;
	isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
	loggedIn: false,
	authToken: '',
	user: null,
	isUserLoaded: false,
};

export function _authReducer(state = initialAuthState, action: AuthActions): AuthState {
	switch (action.type) {
		case AuthActionTypes.Login: {
			const _token: string = action.payload.authToken;
			return {
				loggedIn: true,
				authToken: _token,
				user: action.payload.user,
				isUserLoaded: false,
			};
		}

		case AuthActionTypes.Logout:
			return initialAuthState;

		case AuthActionTypes.UserLoaded: {
			const _user: User = action.payload.user;
			return {
				...state,
				user: _user,
				isUserLoaded: true,
			};
		}

		default:
			return state;
	}
}

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
	return _authReducer(state, action as AuthActions);
}
