/* eslint-disable @typescript-eslint/naming-convention */
import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import localeEs from '@angular/common/locales/es';

import { CookieService } from 'ngx-cookie-service';
import { authInterceptorProviders } from '@core/security/interceptors/auth-interceptor.service';
import { errorHandlerInterceptorProviders } from '@core/security/interceptors/error-handler-interceptor.service';
import { EffectsArray } from '@core/store/effects';
import { CustomSerializer } from '@core/store/serializer/custom-serializer';
import { reducers, metaReducers } from '@core/store';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScSearchPagesModule } from '~shared/components/sc-search-pages/sc-search-pages.module';
import { environment } from 'environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwUpdateNotifierService } from '@utils/services/sw-update-notifier.service';
import { ThemeModule } from '@theme/theme.module';
import { PartialsModule } from '@partials/partials.module';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { HistorialEstadoModule } from './modules/gestion/routing-order/routing-order-lista/routing-order-dialog/historial-estado/historial-estado.module.component';
import { RoutingOrderStore } from './modules/gestion/routing-order/store/routing-order.store';

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
	hostname: 'b-eb7e7655-db2c-471e-b330-9723b6344494-1.mq.us-east-1.amazonaws.com',
	port: 61619,
	username: 'admin',
	password: environment.MQTT_PASSWORD,
	protocol: 'wss',
	path: '/mqtt',
	keepalive: 720,
};

registerLocaleData(localeEs, 'es');

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ThemeModule,
		HttpClientModule,
		AppRoutingModule,
		HistorialEstadoModule,
		PartialsModule,
		// METRONIC
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		ScSearchPagesModule,

		// NGRX
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot(EffectsArray),
		StoreRouterConnectingModule.forRoot({
			stateKey: 'router',
			routerState: RouterState.Minimal,
			serializer: CustomSerializer,
		}),
		StoreDevtoolsModule.instrument({ connectInZone: true }),

		MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: LOCALE_ID,
			useValue: 'es',
		},

		Title,
		CookieService,
		authInterceptorProviders,
		errorHandlerInterceptorProviders,
		SwUpdateNotifierService,
		RoutingOrderStore,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
